/*
 * @Author: hwu
 * @Date: 2020-11-25 13:51:34
 * @Description: 订单接口
 * @LastEditTime: 2024-07-29 22:33:18
 */

import { posHttp, centerHttp } from '@/utils/axios'

/**
 * 获取订单详情
 * @param {String} orderId 订单Id
 * @param {String} userId 用户id
 */
export const getOrderDetailApi = (orderId, userId) => posHttp.get(`/order/getOrderDetail?orderId=${orderId}&userId=${userId}`)

/**
 * 获取订单详情（后买单用）
 * @param {*} orderId
 * @param {*} userId
 * @returns
 */
export const getOrderDetailForPayLaterApi = (orderId, userId) => posHttp.get(`/order/detail/for/pay/later?orderId=${orderId}&userId=${userId}&isStaff=1`)

/**
 * 修改订单类型，堂食、外带
 * @param {String} orderId 订单Id
 * @param {String} userId 用户id
 * @param {Number} serviceType 订单类型
 */
export const changeServiceTypeApi = (orderId, userId, serviceType) =>
  posHttp.get('/order/changeServiceType', {
    params: { orderId, userId, serviceType }
  })

/**
 * 获取门店物业活动列表
 * @param {String} storeId 门店Id
 */
export const listPropertyPromoApi = (storeId, orderId, userId) => posHttp.get(`/order/propertyPromos`, { params: { storeId, orderId, userId } })

/**
 * 批量将物业活动加入订单
 * @param {String} orderId 订单Id
 * @param {String} userId 用户openId
 * @param {String} waiterId 服务员id
 * @param {String} promoFormList 要添加的物业活动
 */
export const addPropertyPromosApi = (orderId, userId, waiterId, promoFormList) => posHttp.post(`/order/setProperties?orderId=${orderId}&userId=${userId}&waiterId=${waiterId}`, promoFormList)

/**
 * 验券
 * @param {String} couponId 券码Id
 * @param {String} orderId 订单Id
 * @param {String} userId 用户Id
 */
export const queryCouponApi = (couponId, orderId, userId) => posHttp.get('/order/coupon', { params: { couponId, orderId, userId } })

/**
 * 勾选活动
 * @param {Object} param 参数
 */
export const refreshPromoApi = (param) => posHttp.post('/order/refreshPromo', param)

/**
 * 共享活动
 * @param {String} orderId 订单Id
 * @param {String} promoId 活动Id
 * @param {String} couponId 券Id
 * @param {String} userId 用户id
 * @param {String} promoUserId 活动所属人id
 * @param {Number} share 是否共享
 */
export const sharePromoApi = (orderId, promoId, couponId, userId, promoUserId, share) =>
  posHttp.get(`/order/sharePromo`, {
    params: { orderId, promoId, couponId, userId, promoUserId, share }
  })

/**
 * 添加商品券商品进入订单
 * @param {String} orderId  订单Id
 * @param {String} promoId 活动id
 * @param {String} couponId  券Id
 * @param {String} userId 用户id
 * @param {String} promoUserId 活动所属人id
 * @param {Array} items 添加的商品
 */
export const addCouponItemsApi = (orderId, promoId, couponId, userId, promoUserId, items) =>
  posHttp.post('/order/couponItems', items, {
    params: { orderId, promoId, couponId, userId, promoUserId }
  })

/**
 * 获取订单内商品（拆分，用于单品备注）
 * @param {String} orderId 订单Id
 * @param {String} userId 用户id
 */
export const getSplitCartItemsApi = (orderId, userId) => posHttp.get(`/order/getSplitCartItems?orderId=${orderId}&userId=${userId}`)

/**
 * 保存单品备注
 * @param {String} orderId 订单Id
 * @param {String} userId 用户id
 */
export const saveItemMarkApi = (orderId, userId, items) => posHttp.post(`/order/setSingleItemMark?orderId=${orderId}&userId=${userId}`, items)

/**
 * 收款下单
 * @param {String} orderId 订单Id
 * @param {String} userId 用户id
 */
export const saveOrderApi = (orderId, userId) => posHttp.get(`/order/saveOrder/${orderId}/${userId}`)

/**
 * 下单前判断是否需要注册
 * @param {String} orderId 订单Id
 * @param {String} userId 用户Id
 */
export const checkMemberRegisteredApi = (orderId, userId) => posHttp.get('/order/member/registered', { params: { orderId, userId } })

/**
 * 个人中心获取订单列表
 * @param {String} userId 用户的openId
 * @param {String} brandCode 品牌
 */
export const listMemberOrdersApi = (userId, brandCode) => posHttp.get('/order/userCenter/orders', { params: { userId, brandCode } })

/**
 * 个人中心获取订单详情
 * @param {String} orderId 订单id
 * @param {String} userId 用户的openId
 */
export const getMemberOrderDetailApi = (orderId, userId, type = 1) =>
  posHttp.get('/order/userCenter/orderDetail', {
    params: { orderId, userId, type }
  })

/**
 * 个人中心获取订单详情（会员卡订单）
 * @param {String} orderId 订单id
 * @param {String} userId 用户的openId
 */
export const getCardOrderDetailApi = (orderId, userId) => posHttp.get('/order/userCenter/cardDetail', { params: { orderId, userId } })

/**
 * 获取订单详情(用于打印账单)
 * @param {String} orderId 订单Id（多个订单号用逗号隔离）
 */
export const getOrderForPrintBillApi = (orderId) => posHttp.get('/bApi/order/print/bill', { params: { orderId } })

/**
 * 获取订单详情(用于打印划菜单)
 * @param {String} orderId 订单Id（多个订单号用逗号隔离）
 */
export const getOrderForPrintMenuApi = (orderId) => posHttp.get('/bApi/order/print/menu', { params: { orderId } })

/**
 * 查看是否有预点单，有的话直接跳到支付页
 * @param {String} storeId 门店Id
 * @param {String} tableId 桌位Id
 * @param {String} userId  用户的openId
 */
export const getPreOrderApi = (storeId, tableId, userId) =>
  posHttp.get('/order/preOrderToNormal', {
    params: { storeId, tableId, userId }
  })

/**
 * 个人中心获取退单详情
 * @param {String} orderId 退单Id
 */
export const getRefundOrderDetailApi = (orderId) => posHttp.get('/bApi/order/userCenter/detail/refund', { params: { orderId } })

/**
 * 订单支付页修改用餐人数
 * @param {String} orderId 退单Id
 * @param {String} userId  用户的openId
 * @param {Number} guestCount 用餐人数
 */
export const changeOrderGuestCountApi = (orderId, userId, guestCount) =>
  posHttp.get('/order/updateGuestCount', {
    params: { orderId, userId, guestCount }
  })

/**
 * 客扫支付。服务员作废订单
 * @param {String} orderId 订单id
 */
export const invalidOrderApi = (orderId) => posHttp.get('/order/invalidOrder', { params: { orderId } })

/**
 * 订单绑定桌码
 * @param {String} orderId 订单id
 * @param {String} userId userid
 * @param {String} qrCode 桌位二维码code
 */
export const setTableInfoApi = (orderId, userId, qrCode) => posHttp.get('/order/setTableInfo', { params: { orderId, userId, qrCode } })

/**
 * 订单绑定顾客手机号
 * @param {String} orderId 订单id
 * @param {String} userId userid
 * @param {String} mobile 手机号
 */
export const setCustomMobileApi = (orderId, userId, mobile) => posHttp.get('/order/setCustomMobile', { params: { orderId, userId, mobile } })

/**
 * 后买单订单下单（第一次进来下单调用）
 * @param {*} orderId
 * @param {*} userId
 * @param mergeOrderId
 * @param isStaff 是否是员工
 * @returns
 */
export const saveOrderAfterPayApi = (orderId, userId, mergeOrderId, isStaff = 1) => posHttp.get('/order/saveOrder/v2', { params: { orderId, userId, mergeOrderId, isStaff } })

/**
 * 已下单的订单详情（确认订单调用，适用于后买单的确认订单）
 * @param {*} orderId
 * @returns
 */
export const getOrderDetailAfterPayApi = (orderId) => posHttp.get('/order/getOrderDetail/v2', { params: { orderId } })

/**
 * 获取有未支付订单的桌位（确认订单的加单功能使用）
 * @param {*} storeId
 * @returns
 */
export const getUnPayOrderListApi = (storeId) => posHttp.get('/bApi/order/table/unPay/orders', { params: { storeId } })

/**
 * 获取门店下单桌位列表
 * @param {*} storeId
 * @returns
 */
export const listStoreOrderTablesApi = (storeId) => centerHttp.get('/bApi/order/tables', { params: { storeId } })

/**
 * 服务员确认订单有效
 * @param {*} orderId
 * @param {*} waiterId 操作员的userId
 * @param {*} result 1有效，0无效
 * @param {*} mergeId 合单id
 * @returns
 */
export const orderConfirmApi = (orderId, waiterId, result, mergeId) => posHttp.get('/order/confirm', { params: { orderId, waiterId, result, mergeId } })

/**
 * 设置收货人信息
 * @param {*} orderId 订单Id
 * @param {*} userId 用户的openId
 * @param {*} consigneeInfo 收货人信息
 * @returns
 */
export const setConsigneeInfoApi = (orderId, userId, consigneeInfo) => posHttp.post(`/order/setConsigneeInfo?orderId=${orderId}&userId=${userId}`, consigneeInfo)

/**
 * 抖音外卖下单
 * @param {*} orderId
 * @param {*} userId
 * @param orderNum
 * @returns
 */
export const saveOrderForDouYinTakeOutApi = (orderId, userId, orderNum) => posHttp.get('/order/saveOrder/takeOut', { params: { orderId, userId, orderNum } })

/**
 * 添加加价购商品
 * @param orderId
 * @param userId
 * @param promoId
 * @param addItemInfo 加价购商品信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addBuyItemsApi = (orderId, userId, promoId, addItemInfo) => posHttp.post(`/order/addBuyItems?orderId=${orderId}&userId=${userId}&promoId=${promoId}`, addItemInfo)

/**
 * 删减加价购商品
 * @param orderId
 * @param userId
 * @param itemId
 * @param promoId
 * @param productionModelId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const subAddBuyItemsApi = (orderId, userId, itemId, promoId, productionModelId) => posHttp.get('/order/subAddBuyItems', { params: { orderId, userId, itemId, promoId, productionModelId } })

/**
 * 进挂账模式下单
 * @param {*} orderId
 * @param {*} userId
 * @returns
 */
export const saveOrderCreditApi = (orderId, userId) => posHttp.get('/order/saveOrder/credit', { params: { orderId, userId } })

/**
 * 政府消费券——放弃收款
 * @param param
 * @returns {Promise<AxiosResponse<any>>}
 */
export const giveUpGovernmentOrderApi = (param) => posHttp.post('/order/government/coupon/giveUp', param)
